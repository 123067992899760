// External Imports
import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { Box, Container, Grid, Typography } from "@mui/material";
import { SummaryCard } from "../components/account/SummaryCard";
import { ProfileDetails } from "../components/account/ProfileDetails";

// Internal Imports
import { handle401 } from "../utils/auth.ts";
import { getUser } from "../api/accounts.ts";

export default function Account() {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [userCurrent, setUserCurrent] = useState(false);

  function refreshUser() {
    setError(null);
    getUser()
      .then((result) => {
        setUser(result);
        setUserCurrent(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  useEffect(refreshUser, [userCurrent]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}>
      <Container maxWidth="lg">
        <Typography sx={{ mb: 3 }} variant="h4">
          Profile
        </Typography>
        {!error && (
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <SummaryCard user={user} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails user={user} setUserCurrent={setUserCurrent} />
            </Grid>
          </Grid>
        )}
        {error && <Alert severity="error">{error.message}</Alert>}
      </Container>
    </Box>
  );
}
