import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default function SummaryPage({ updateFormData, formData }) {
  return (
    <React.Fragment>
      <Typography variant="h6" mb={3} gutterBottom>
        Issue Description
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          Please provide a brief subject describing your issue. <br /> <br />
          <TextField
            fullWidth
            onChange={updateFormData("subject")}
            value={formData["subject"]}
            label="Subject"
            InputLabelProps={{ shrink: true }}
            required
            id="subject"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          Please provide a few sentences describing the issue you are facing to
          give us some context for how we can help you.
          <br /> <br />
          <TextField
            fullWidth
            multiline
            onChange={updateFormData("description")}
            value={formData["description"]}
            InputLabelProps={{ shrink: true }}
            required
            rows={5}
            label="Description of Issue"
            id="description"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
