// External imports
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// Internal imports
import peopleLaughing from "../images/volunteers.webp";
import { useWindowDimensions } from "../utils/window";

const intro = {
  id: 0,
  header:
    // "We're a group of people driven to change U.S. healthcare, with experience in software, machine learning, law, health policy, and the difficulties of navigating our own health care.",
    "We're a group of people driven to change U.S. healthcare.",
  image: null,
  text_one: `Persius is run by a group of people who have extensive experience working in, and dealing with, the systems in place in
  U.S. healthcare. We've been on the receiving end of denials for coverage critical to the health of ourselves
  and our loved ones, spent endless hours on hold attempting to proactively get access to crucial information which should be transparent, and
  seen those we love suffer the consequences of the greed of corporate executives. We are prepared to fight to see these situations change. `,
  // text_one: `Persius is run by a group of people who have extensive experience working in, and dealing with, the atrocious systems in place in
  // U.S. healthcare. Our contributors include medical students, medical doctors, lawyers, policy experts, software engineers, machine learning researchers,
  // and data scientists, with collective expertise in virtually all components of our healthcare system. We've been on the receiving end of denials for coverage critical to the health of ourselves
  // and our loved ones, spent endless hours on hold attempting to proactively get access to crucial information which should be transparent, and
  // seen those we love suffer the consequences of the greed of old men in suits. We are prepapred to fight to see these situations change. `,

  text_two: `Persius is currently supported and maintained by a few individuals. We currently raising money to help support our services and mission. If you are interested in supporting Persius in any way, please reach out.`,
};

export default function Us() {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();

  // Hack because I suck with flexbox
  const bannerImgOffset = width > 500 ? "35vh" : "5vh";

  const styles = {
    bannerContainer: {
      // backgroundSize: `cover`,
      // backgroundPosition: `center center`,
      backgroundColor: theme.palette.background.paper2,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%239C92AC' fill-opacity='0.2'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      height: "100vh",
      width: "100vw",
      opacity: 0.9,
      filter: "brightness(100%)",
    },
  };
  return (
    <React.Fragment>
      {/* headers */}
      <Container
        component="header"
        maxWidth="false"
        style={styles.bannerContainer}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          justify="center">
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              variant="h2"
              align="center"
              color={theme.palette.text.primary}
              mt={"25vh"}
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "3px",
                textDecorationColor: theme.palette.primary.dark,
                textUnderlineOffset: "15px",
              }}>
              <b>Who We Are</b>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              mt={"5vh"}
              color={theme.palette.text.primary}>
              <b>
                We're a group of people <br /> driven to change U.S. healthcare.
              </b>
            </Typography>
          </Grid>
          <Grid maxWidth="false" item xs={12} md={6} align="center">
            <Box
              mt={bannerImgOffset}
              component="img"
              src={peopleLaughing}
              alt={"A group of friends laughing."}
              sx={{
                width: "70%",
                border: `3px solid` + theme.palette.primary.dark,
                borderRadius: "10px 10px 10px 10px",
                backgroundSize: `contain`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {/* Intro */}
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          pt: "10vh",
          pb: "0vh",
          mx: "10vw",
        }}>
        <Typography
          variant="h5"
          align="center"
          justifyContent="left"
          color={theme.palette.text.dark}
          paragraph>
          {intro.text_one}
        </Typography>
        <Typography
          my={"5vh"}
          variant="h5"
          align="center"
          justifyContent="left"
          color={theme.palette.text.dark}
          paragraph>
          {intro.text_two}
        </Typography>
      </Box>
    </React.Fragment>
  );
}
