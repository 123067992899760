// External imports
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { Navigate, createSearchParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Internal Imports
import { login } from "../utils/auth.ts";

export default function Login(props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);
  const loc_state = useLocation();
  let isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;

  useEffect(() => {
    if (isLoggedIn && loc_state?.state?.path) {
      navigate(loc_state?.state?.path, { replace: true });
    } else if (isLoggedIn) {
      navigate("/");
    } else if (needsEmailVerification) {
      navigate({
        pathname: "/verify-email",
        search: createSearchParams({
          username: username,
        }).toString(),
      });
    }
  }, [isLoggedIn, needsEmailVerification, loc_state, navigate, username]);

  const handleSubmit = async (_) => {
    setError("");
    try {
      const data = await login(username, password);

      if ("access_token" in data) {
        setIsLoggedIn(true);
      } else if (
        data.detail === "User exists. Login requires email verification."
      ) {
        setNeedsEmailVerification(true);
      } else {
        setError("Network error.");
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    }
  };

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              onChange={(e) => setUsername(e.currentTarget.value)}
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link component={RouterLink} to={"/contact"} variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link component={RouterLink} to={"/signup"} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Grid container mt={3} justifyContent="center">
            {error && (
              <Grid item justifyContent="center">
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
