// External Imports
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Outlet, Link as RouterLink } from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import Container from "@mui/material/Container";

// Internal Imports
import { getRegions } from "../api/resources.ts";
import { handle401 } from "../utils/auth.ts";

const sections = [
  {
    title: "Prior Authorization Requests",
    description_pars: [`Depending on your commercial insurance provider, some procedures, treatments and drugs may require prior authorization (which can also be called 
    preapproval, or precertification). To determine if a service requires prior authorization, a good place to start is your full benefits contract (not your Summary of Benefits).
    The contract should make explicit what the prior authorization process entails for your plan in general, and state either explicitly, or implicitly through reference to
    external medical policy guidelines, whether a given service requires prior authorization.`, `If you need
    help determining whether your care will require pre-approval, contact us
    and we'll help you figure out what documentation is necessary and how to get
    approval as soon as possible.`],
  },
  {
    title: "Filing an Appeal",
    description_pars: [`Most people with health insurance have the
    legal right to appeal adverse claims decisions made by their insurers or third party administrators. In commercial markets,
    such appeals processes typically consist of two phases:`],
  },
  {
    title: "State Specific Information",
    description_pars: [`As described above, the exact procedures necessary to resolve
    an insurance claim denial tend to vary by commercial market segment, plan, and state. Please select your state below
    to be directed to state specific information about the details of the processes above.`],
  },
];

export default function CoverageHelp() {
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [region, setRegion] = useState("");

  const UpdateRegion = (e) => {
    setRegion(e.target.value);
  };

  useEffect(() => {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
          setRegion(result.data[0].name);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      })
      .finally(() => setRegionsLoading(false));
  }, []);

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          (Commercial) Self Help Tools
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={2} mb={4} alignItems="flex-end">
          {sections.map((section) => (
            <Grid item key={section.title} xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  title={section.title}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    {section.description_pars.map((description, idx) => (
                    <Typography mt={3} key={idx} variant="body1" color="text.primary">
                      {description}
                    </Typography>))
                    }

                    {section.title === "Filing an Appeal" && (
                      <div>
                        <br />
                        <Accordion
                          TransitionProps={{ unmountOnExit: true }}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light"
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Internal Appeals</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.paper,
                            }}
                          >
                            <Typography paragraph={true} variant="body1">
                              If you receive a claim denial, you may contact your insurer
                              directly to start an appeal process with an
                              internal review board. You should wait until you
                              receive formal written notice of the denial before
                              beginning this process. Your internal appeal will need to be submitted
                              within a certain number of days once you receive your denial, and your insurer 
                              will similarly have a specific timeline within
                              which they must respond to your internal appeal. The exact timelines
                              depend on your plan details, but should be specified in your denial
                              letter and your benefits contract.
                            </Typography>

                            <Typography paragraph={true} variant="body1">
                              To file the internal appeal, you need to
                              determine where your correspondence should be
                              sent. This should be specified in both your denial letter,
                              and in your full benefits contract. Depending on your insurer, you will need to
                              submit either a specific form, or a written letter that
                              documents information such as your name, denied claim number, and
                              member ID, as well as a description of why you
                              believe the claim should be covered. It is
                              important here to include as much detail and
                              supporting documentation as possible. This could
                              include medical opinions from doctors involved in
                              your treatment, reference to specific clauses in
                              your policy plan, or written proof of faulty
                              information you were provided prior to the claim submission.
                              A useful approach in successfully appealing denials is
                              to document as much as possible, abd get everything in writing.
                            </Typography>

                            <Typography paragraph={true} variant="body1">
                              Once the appeal is
                              submitted, the clock starts ticking on the insurer's
                              deadline to respond. For emergency situations
                              you can request an expedited internal review which will be 
                              decided more quickly, and typically you can simultaneously
                              request an external review (see below) at the same time as the internal review. In
                              all cases, if your internal appeal is denied you
                              may then file an external appeal.
                            </Typography>

                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                          TransitionProps={{ unmountOnExit: true }}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light"
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>External Appeals</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.paper,
                            }}
                          >
                            <Typography paragraph={true} variant="body1">
                              If you filed an internal appeal and the decision
                              was denied, or if your situation is medically urgent, 
                              then you may file an external
                              appeal. This will involve having your appeal
                              reviewed by an indpendent third party source
                              unaffiliated with your insurer. Insurers are then
                              legally obligated to act based on the decision of
                              the external third party.
                            </Typography>
                            <Typography paragraph={true} variant="body1">
                              Instructions for filing an external appeal vary plan and 
                              market segment details. In fully insured contexts,
                              different states administer the external review processes
                              in different ways. If you received a final
                              response to an internal appeal, the
                              final determination letter should have
                              instructions for filing an external appeal
                              included. If it is not there for any reason, the information should
                              also  be
                              detailed in your full benefits contract. If you are unable to find instructions for
                              filing an external appeal,
                              please contact us and we will direct you to the
                              appropriate place.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </Box>
                </CardContent>
                {section.title === "State Specific Information" && error && (
                  <Grid container mb={3} justifyContent="center">
                    <Alert severity="error">{error.message}</Alert>
                  </Grid>
                )}
                {section.title === "State Specific Information" &&
                  !error &&
                  !regionsLoading && (
                    <div>
                      <CardActions>
                        <Grid container spacing={3} alignItems="flex-end">
                          <Grid item key="select" xs={12} sm={12} md={4}>
                            <TextField
                              fullWidth
                              label="State or Region"
                              name="select_state_or_region"
                              InputLabelProps={{ shrink: true }}
                              onChange={UpdateRegion}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={region}
                              variant="outlined"
                            >
                              <option key="no_region" value="" disabled>
                                Select a Region
                              </option>
                              {regions.map((option) => (
                                <option key={option.uid} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            key="state-guide-button"
                            xs={12}
                            sm={12}
                            md={4}
                          >
                            <Button
                              component={RouterLink}
                              to={`/resources/self-help-tools/${region
                                .split(" ")
                                .join("-")}`}
                              color="secondary"
                              variant="contained"
                            >
                              {"View State Resources"}
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions>
                      <Outlet />
                    </div>
                  )}
                {section.title === "State Specific Information" &&
                  regionsLoading && (
                    <Grid container justifyContent="center">
                      <CircularProgress color="inherit" />
                    </Grid>
                  )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
