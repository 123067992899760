import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { logout } from "../utils/auth";
import { useWindowDimensions } from "../utils/window";
import Logo from "../images/favicon.webp";
import LogoName from "../images/persius_f1_maroon.webp";

function DropMenu(props) {
  const navigate = useNavigate();
  const theme = props.theme;
  const paths = props.paths; // Path-label pairs in 2d array
  const menu_name = props.menu_name;
  const logout_link = props.logout_link;
  const login_setter = props.login_setter;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogOut = () => {
    login_setter(false);
    logout();
    handleClose();
    navigate("/");
  };

  return (
    <div>
      <Button
        id={`${menu_name}-button`}
        aria-controls={open ? `${menu_name}-button` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : "false"}
        onClick={handleClick}>
        {menu_name}
      </Button>
      <Menu
        id={`${menu_name}-menu`}
        anchorEl={anchorEl}
        open={open}
        autoFocus={false}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        MenuListProps={{
          "aria-labelledby": `${menu_name}-button`,
        }}
        variant="menu">
        {paths.map(function (item, idx) {
          if (item[2] === "RouterLink") {
            return (
              <MenuItem
                key={idx}
                onClick={handleClose}
                component={RouterLink}
                to={item[0]}
                sx={{
                  color: theme.palette.text.paper,
                }}>
                {item[1]}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={idx}
                href={item[0]}
                component={Link}
                to={item[0]}
                sx={{
                  color: theme.palette.text.paper,
                }}>
                {item[1]}
              </MenuItem>
            );
          }
        })}
        {logout_link && (
          <MenuItem
            component="button"
            onClick={LogOut}
            sx={{
              color: theme.palette.text.paper,
            }}>
            Logout
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default function NavBar(props) {
  const theme = useTheme();

  // State
  const isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;

  const { height, width } = useWindowDimensions();

  if (isLoggedIn) {
    return (
      <AppBar
        style={{ background: theme.palette.background.default }}
        position="fixed"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}>
        <Toolbar sx={{ flexWrap: "nowrap" }}>
          <Box
            component="img"
            sx={{
              height: 52,
              width: 50,
              mx: 1,
            }}
            alt="Persius logo"
            src={Logo}
          />
          <Container sx={{ flexGrow: 1 }}></Container>

          <Button component={RouterLink} to={"/"} sx={{ my: 1, mx: "1vw" }}>
            Home
          </Button>

          <DropMenu
            theme={theme}
            paths={[
              ["/resources/self-help-tools", "Self Help Tools", "RouterLink"],
              ["/claims", "Denied Claims Help", "RouterLink"],
            ]}
            menu_name={"Resources"}
            logout_link={false}
            sx={{ my: 1, mx: "1vw" }}
          />

          {/* <Button
            component={RouterLink}
            to={"/claims"}
            sx={{ my: 1, mx: ".5vw" }}>
            Denied Claims
          </Button> */}

          <DropMenu
            theme={theme}
            paths={[
              ["/account/profile", "Profile", "RouterLink"],
              ["/account/coverage", "Coverage", "RouterLink"],
            ]}
            menu_name={"Account"}
            logout_link={true}
            login_setter={setIsLoggedIn}
            sx={{ my: 1, mx: "1vw" }}
          />
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <AppBar
      style={{ background: theme.palette.background.default }}
      elevation={0}
      position="fixed"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}>
      <Toolbar sx={{ flexWrap: "nowrap" }}>
        {width >= 300 && width < 500 && (
          <React.Fragment>
            <Box component={RouterLink} to={"/"}>
              <Box
                component="img"
                sx={{
                  height: 52,
                  width: 50,
                  mx: 1,
                  my: 1,
                }}
                alt="Persius logo"
                src={Logo}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </React.Fragment>
        )}
        {width >= 500 && (
          <React.Fragment>
            <Box component={RouterLink} to={"/"}>
              <Box
                component="img"
                sx={{
                  height: 52,
                  width: 50,
                  mx: 1,
                  my: 0,
                }}
                alt="Persius logo"
                src={Logo}
              />
            </Box>
            <Box component={RouterLink} to={"/"}>
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 250,
                  mx: 1,
                  my: 2,
                }}
                alt="Persius Title"
                src={LogoName}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </React.Fragment>
        )}

        <DropMenu
          theme={theme}
          paths={[
            // ["https://blog.persius.org", "Blog", "Link"],
            ["/about/mission", "Mission", "RouterLink"],
            ["/about/services", "Services", "RouterLink"],
            ["/about/us", "Us", "RouterLink"],
          ]}
          menu_name={"About"}
          logout_link={false}
          sx={{ my: 1, mx: "1vw" }}
        />

        {/* <Button component={Link} href="https://blog.persius.org">
          Blog
        </Button> */}

        <Button
          component={RouterLink}
          to={"/login"}
          variant="outlined"
          sx={{ my: 1, mx: ".5vw" }}>
          Login
        </Button>

        <Button
          component={RouterLink}
          to={"/signup"}
          variant="contained"
          sx={{ my: 1, mx: ".5vw" }}>
          Sign Up
        </Button>
      </Toolbar>
    </AppBar>
  );
}
