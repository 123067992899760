// External Imports
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DraftsIcon from "@mui/icons-material/Drafts";
import UploadIcon from "@mui/icons-material/Upload";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import { visuallyHidden } from "@mui/utils";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Alert } from "@mui/material";
import Chip from "@mui/material/Chip";

// Internal Imports
import { useNavigate } from "react-router-dom";
import { getUser } from "../api/accounts.ts";
import {
  getClaims,
  getAllClaims,
  alterClaim,
  alterClaimAdmin,
} from "../api/claims.ts";
import { handle401 } from "../utils/auth.ts";
import ChatModal from "../components/Chat";
import UploadSupportingDocsModal from "../components/claims/UploadSupportingDocsModal";
import UploadAppealModal from "../components/claims/UploadAppealModal";

// Descending comparator on orderBy attribute.
// Assumes (>, <) operators will suffice for desired ordering.
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Get a ascending or descending version of comparator, as desired
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Headers
const headCells = [
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "careDate",
    numeric: false,
    disablePadding: false,
    label: "Care Date",
  },
  {
    id: "claimID",
    numeric: false,
    disablePadding: false,
    label: "Claim ID",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all claims",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="none" align="left"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, onResolveClick, onReopenClick } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: (theme) =>
          theme.palette.mode === "light"
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} claims selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%"
          }}
          color="inherit"
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          Claims
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
          <Tooltip title="Mark Resolved">
            <IconButton onClick={onResolveClick}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reopen Claim">
            <IconButton onClick={onReopenClick}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onResolveClick: PropTypes.func.isRequired,
};

function Row(props) {
  const { isItemSelected, handleClick, labelId, row, pageState, setPageState } =
    props;
  const [open, setOpen] = React.useState(false);
  const user = props.user;
  const [rowStatus, setRowStatus] = React.useState(row.status);

  function colorStatus(status) {
    switch (status) {
      case "Submitted":
        return "success";
      case "Resolved":
        return "secondary";
      case "User Action Required":
        return "primary";
      case "Pending":
        return "primary";
      default:
        return "error";
    }
  }

  function changeStatus(status, claim_uid) {
    alterClaimAdmin(status, claim_uid).catch((error) => {
      if (error?.response?.status === 401) {
        handle401();
      }
    });
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRowStatus(value);
    changeStatus(value, row.uid);
    // Submit change to status of given claim.
  };

  let chipSelect = null;
  if (user.is_superuser || user.is_staff) {
    chipSelect = (
      <Select
        onChange={handleChange}
        value={rowStatus}
        renderValue={(selected) => (
          <Chip label={selected} color={colorStatus(selected)}></Chip>
        )}
      >
        <MenuItem value={"Submitted"}>Submitted</MenuItem>
        <MenuItem value={"Pending"}>Pending</MenuItem>
        <MenuItem value={"Resolved"}>Resolved</MenuItem>
        <MenuItem value={"User Action Required"}>User Action Required</MenuItem>
      </Select>
    );
  } else {
    chipSelect = <Chip label={row.status} color={colorStatus(row.status)} />;
  }
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.uid}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, row.uid)}
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell align="left">{row.subject}</TableCell>
        {!row.marked_resolved && (
          <TableCell align="left">{chipSelect}</TableCell>
        )}
        {row.marked_resolved && (
          <TableCell align="left">
            {" "}
            <Chip label="Resolved" color="secondary" />
          </TableCell>
        )}
        <TableCell align="left">{row.care_date}</TableCell>
        <TableCell align="left">{row.uid.slice(0, 8)}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={row.uid + "subrow"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                  <Typography variant="h5" mb={4} gutterBottom component="div">
                    Claim Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Container maxWidth="md">
                        <Typography variant="h6">Insurer</Typography>
                        <Typography paragraph>{row.insurer.name}</Typography>
                      </Container>
                      <Container maxWidth="md">
                        <Typography variant="h6">Plan</Typography>
                        <Typography paragraph>{row.plan.name}</Typography>
                      </Container>{" "}
                      <Container maxWidth="md">
                        <Typography variant="h6">Care Date</Typography>
                        <Typography paragraph>{row.care_date}</Typography>
                      </Container>
                    </Grid>
                    <Grid item xs={8}>
                      <Container maxWidth="md">
                        <Typography variant="h6">Summary</Typography>
                        <Typography
                          paragraph
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {row.description}
                        </Typography>
                      </Container>
                      {row.docs.length > 0 && (
                        <Container maxWidth="md">
                          <Typography variant="h6">
                            Supporting Documents
                          </Typography>
                          <List>
                            {row.docs.map((doc, idx) => {
                              return (
                                <ListItem
                                  component={Link}
                                  key={doc.uid}
                                  href={doc.url}
                                >
                                  Document {idx + 1}{" "}
                                </ListItem>
                              );
                            })}
                          </List>
                        </Container>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="h5" mb={4} gutterBottom component="div">
                    Actions
                  </Typography>
                  {row.appeal_url && (
                    <ListItem>
                      <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon>
                      <Typography display="block">
                        <Link href={row.appeal_url}>View Appeal Letter</Link>
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemIcon>
                      <UploadIcon />
                    </ListItemIcon>
                    <UploadSupportingDocsModal
                      claim_uid={row.uid}
                      pageState={pageState}
                      setPageState={setPageState}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ChatBubbleIcon />
                    </ListItemIcon>
                    <ChatModal uid={row.uid} user={user} />
                  </ListItem>
                  {(user.is_superuser || user.is_staff) && (
                    <ListItem>
                      <ListItemIcon>
                        <UploadIcon />
                      </ListItemIcon>
                      <UploadAppealModal
                        claim_uid={row.uid}
                        pageState={pageState}
                        setPageState={setPageState}
                      />
                    </ListItem>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("subject");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState("");
  const [user, setUser] = useState({});
  const [firstID, setFirstID] = useState(null);
  const [lastID, setLastID] = useState(null);
  const [pageState, setPageState] = useState({
    page: 0,
    prevPage: 0,
  });
  const [count, setCount] = useState(0);

  function refreshUser() {
    getUser()
      .then((result) => {
        setUser(result);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  function refreshClaims() {
    let params = {};
    params.limit = 5;
    if (pageState.page - pageState.prevPage > 0) {
      params.id_before = lastID;
    } else if (pageState.page - pageState.prevPage < 0) {
      params.id_after = firstID;
    } else if (firstID !== null) {
      if (pageState.page > 0) {
        params.id_before = firstID + 1;
      }
    }
    let urlParams = new URLSearchParams(params);
    if (user.is_superuser || user.is_staff) {
      getAllClaims(urlParams)
        .then((result) => {
          setRows(result.data.claims);
          setCount(result.data.count);
          if (result.data.claims.length > 0) {
            setFirstID(result.data.claims[0].id);
            setLastID(result.data.claims[result.data.claims.length - 1].id);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    } else {
      getClaims(urlParams)
        .then((result) => {
          setRows(result.data.claims);
          setCount(result.data.count);
          if (result.data.claims.length > 0) {
            setFirstID(result.data.claims[0].id);
            setLastID(result.data.claims[result.data.claims.length - 1].id);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    }
  }
  useEffect(refreshUser, []);

  useEffect(refreshClaims, [user, pageState]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.uid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleResolveClick = () => {
    alterClaim(true, selected);
    setTimeout(refreshClaims, 500);
  };

  const handleReopenClick = () => {
    alterClaim(false, selected);
    setTimeout(refreshClaims, 500);
  };

  const handleClick = (event, uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPageState({ page: newPage, prevPage: pageState.page });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageState({ page: 0, prevPage: 0 });
  };

  const isSelected = (uid) => selected.indexOf(uid) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    rows.length >= rowsPerPage
      ? Math.min(
          rowsPerPage - rows.length,
          (1 + pageState.page) * rowsPerPage - rows.length
        )
      : 0;
  if (!error) {
    return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onResolveClick={handleResolveClick}
            onReopenClick={handleReopenClick}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.uid);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Row
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                        labelId={labelId}
                        key={row.uid}
                        row={row}
                        user={user}
                        pageState={pageState}
                        setPageState={setPageState}
                      />
                    );
                  }
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={parseInt(count)}
            rowsPerPage={rowsPerPage}
            page={pageState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Paper>
      </Box>
    );
  }
}

export default function ViewClaims() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
        justifyContent="center"
      >
        <Container maxWidth="medium">
          <Button
            type="submit"
            variant="contained"
            onClick={() => navigate("/claims/submit-claim")}
            sx={{ mt: 3, mb: 2, ml: 0 }}
          >
            Submit a Denied Claim
          </Button>
          <EnhancedTable />
        </Container>
      </Box>
    </React.Fragment>
  );
}
