//External Imports
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

//Internal Imports
import { getClaims, getAllClaims } from "../api/claims.ts";
import { handle401 } from "../utils/auth.ts";

export default function ClaimsSummary(props) {
  const navigate = useNavigate();
  const [claims, setClaims] = useState([]);
  const [error, setError] = useState(null);
  let user = props.user;

  function refreshClaims() {
    let params = {};
    params.limit = 5;
    let urlParams = new URLSearchParams(params);
    if (user.is_superuser || user.is_staff) {
      getAllClaims(urlParams)
        .then((result) => {
          setClaims(result.data.claims);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    } else {
      getClaims(urlParams)
        .then((result) => {
          setClaims(result.data.claims);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    }
  }

  useEffect(refreshClaims, [user]);

  if (!error) {
    return (
      <Card {...props}>
        <CardHeader
          title="Recently Denied Claims"
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{
            align: "center",
          }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <CardContent>
        <Box sx={{ minWidth: "100%", overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Insurer</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Care Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claims.map((claim) => (
                <TableRow hover key={claim.uid}>
                  <TableCell>{claim?.insurer?.name}</TableCell>
                  <TableCell>{claim?.plan?.name}</TableCell>
                  <TableCell>{claim.subject}</TableCell>
                  <TableCell>{claim.care_date}</TableCell>
                  <TableCell>
                    {!claim.marked_resolved && (
                      <Chip
                        label={claim.status}
                        color={
                          (claim.status === "Submitted" && "success") ||
                          (claim.status === "Resolved" && "secondary") ||
                          (claim.status === "User Action Required" &&
                            "primary") ||
                          "error"
                        }
                      />
                    )}
                    {claim.marked_resolved && (
                      <Chip label="Resolved" color="secondary" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              navigate("/claims");
            }}>
            View All Claims
          </Button>
        </Box>
      </Card>
    );
  } else {
    return (
      <Card>
        {" "}
        <Alert severity="error">{error}</Alert>
      </Card>
    );
  }
}
