// External imports
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// Internal Imports
import exec from "../images/exec.webp";
import iv from "../images/iv.webp";
import volunteerWheelchair from "../images/volunteerWheelchair.webp";
import { useWindowDimensions } from "../utils/window";

const intro = {
  id: 0,
  header:
    "Our mission is to advocate for patients, end injustices, and improve systems in U.S. healthcare.",
  image: null,
  text_one: `The health care industry in the U.S. is broken.
    We believe collective action is the key to fighting injustices in our systems, and to achieving accountability. We believe the U.S. populace is ready
    to act collectively to bring about the change they seek in healthcare.`,

  text_two: `While we recognize there are serious issues in U.S. healthcare stemming from providers, insurers, pharmaceutical companies, and many other sources,
    our initial focus is on building systems and providing information that can be used to improve the practices of health insurers.
    The most efficient way to do so is to convince insurers
    that they will no longer profit by ripping people off and acting in bad faith.
    Together we can ensure that such behavior will no longer benefit them, and will no longer be tolerated.`,

  text_three: `We hope to see our mission become irrelevant and for government run healthcare to remove the unjust practices of private, profit driven corporations in U.S. healthcare. `,
};
const sections = [
  {
    id: 0,
    header: "People Are Sick",
    image: iv,
    image_alt: "Picture of ill person.",
    imagePosition: "right",
    text: `Claims denials in the U.S. pose serious problems for patients. They cause delays, forgone care, and detrimental health outcomes. 
    Sometimes patients receive services despite denials, incurring bills, debt, and collections lawsuits. 
    In both cases, there are calamitous effects for the most vulnerable. People deserve access to the services afforded
    to them by their health insurance polciies. They should be able to focus on their wellbeing, rather than holding billion dollar corporations accountable
    for inappropriate delays and denials.
`,
  },
  {
    id: 1,
    header: "Health Insurance Is Broken",
    image: exec,
    image_alt: "Picture of exec.",
    imagePosition: "right",
    text: `Insurers routinely deny claims inappropriately; that is, in ways inconsistent with law, their own contracts, and consensus opinion
    among medical experts. As a society we recognize that individuals should be held responsible when they break contractual obligations or law, but due to their influence, power, and ability
    to incur hefty legal fees, we continue to see insurers get away with illegal practices with palatable, strictly financial consequences. This is unacceptable.
    We work to expose and change these practices.`,
  },
];

export default function Mission() {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();

  // Hack because I suck with flexbox
  const bannerImgOffset = width > 500 ? "35vh" : "5vh";

  const styles = {
    bannerContainer: {
      // backgroundSize: `cover`,
      // backgroundPosition: `center center`,
      backgroundColor: theme.palette.background.paper2,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%239C92AC' fill-opacity='0.2'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      height: "100vh",
      width: "100vw",
      opacity: 0.9,
      filter: "brightness(100%)",
    },
  };
  return (
    <React.Fragment>
      {/* headers */}

      <Container
        component="header"
        maxWidth="false"
        style={styles.bannerContainer}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          justify="center"
          alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              variant="h2"
              align="center"
              mt={"25vh"}
              color={theme.palette.text.primary}
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "3px",
                textDecorationColor: theme.palette.primary.dark,
                textUnderlineOffset: "15px",
              }}>
              <b>What We're About</b>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              mt={"5vh"}
              color={theme.palette.text.primary}>
              <b>
                Our mission is to advocate for patients, end <br />
                injustices, and improve systems in U.S. healthcare.
              </b>
            </Typography>
          </Grid>
          <Grid maxWidth="false" item xs={12} md={6} align="center">
            <Box
              mt={bannerImgOffset}
              component="img"
              src={volunteerWheelchair}
              alt={"A volunteer and patient."}
              sx={{
                width: "70%",
                border: `3px solid` + theme.palette.primary.dark,
                borderRadius: "10px 10px 10px 10px",
                backgroundSize: `contain`,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {/* Intro */}
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          pt: "10vh",
          pb: "0vh",
          mx: "10vw",
        }}>
        <Typography
          variant="h5"
          align="center"
          justifyContent="left"
          color={theme.palette.text.dark}
          paragraph>
          {intro.text_one}
        </Typography>
        <Typography
          my={"5vh"}
          variant="h5"
          align="center"
          justifyContent="left"
          color={theme.palette.text.dark}
          paragraph>
          {intro.text_two}
        </Typography>

        {/* <Typography
          variant="h5"
          align="center"
          justifyContent="left"
          color={theme.palette.text.dark}
          paragraph>
          {intro.text_three}
        </Typography> */}
      </Box>
      {/* Sections */}
      {sections.map((section) => {
        if (section.imagePosition === "right") {
          return (
            <Box
              key={section.id}
              sx={{
                bgcolor: theme.palette.background.default,
                pt: 4,
                pb: 6,
                mx: "10vw",
              }}>
              <Typography
                my={5}
                component="h3"
                variant="h4"
                align="left"
                color={theme.palette.primary.main}
                gutterBottom
                sx={{
                  textDecoration: "underline",
                  textDecorationThickness: "3px",
                  textDecorationColor: theme.palette.text.primary,
                  textUnderlineOffset: "8px",
                }}>
                {section.header}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} s={9} md={9}>
                  <Typography
                    variant="h5"
                    align="left"
                    justifyContent="left"
                    color={theme.palette.text.dark}
                    paragraph>
                    {section.text}
                  </Typography>
                </Grid>
                <Grid item xs={1} s={1} md={1}>
                  <Box
                    component="img"
                    src={section.image}
                    alt={section.image_alt}
                    sx={{
                      height: "200px",
                      border: `3px solid` + theme.palette.primary.dark,
                      borderRadius: "10px 10px 10px 10px",
                      backgroundSize: `cover`,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        } else {
          return (
            <Box
              key={section.id}
              sx={{
                bgcolor: theme.palette.background.default,
                pt: 4,
                pb: 6,
                mx: "10vw",
              }}>
              <Typography
                my={5}
                component="h3"
                variant="h4"
                align="left"
                color={theme.palette.primary.main}
                gutterBottom>
                {section.header}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} s={8} md={4}>
                  <Box
                    component="img"
                    src={section.image}
                    alt={section.image_alt}
                    sx={{
                      height: "100px",
                      border: `3px solid` + theme.palette.primary.dark,
                      borderRadius: "10px 10px 10px 10px",
                      backgroundSize: `cover`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} s={9} md={9}>
                  <Typography
                    variant="h5"
                    align="left"
                    justifyContent="left"
                    color={theme.palette.text.dark}
                    paragraph>
                    {section.text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        }
      })}
    </React.Fragment>
  );
}
