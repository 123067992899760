//External Imports
import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

//Internal Imports
import { submitSupportingDoc } from "../../api/claims.ts";
import UploadClaimDocs from "./UploadClaimDocs";

export function UploadSupportingDocs(props) {
  const [progress, setProgress] = useState(0);
  const claimUid = props.claim_uid;
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  let pageState = props.pageState;
  let setPageState = props.setPageState;

  const defaultForm = {
    docsOnFile: [],
  };

  // Form data state
  const [formData, setFormData] = useState(defaultForm);

  const updateFormData =
    (input, method = "event") =>
    (e) => {
      setError("");
      let value = "";
      if (method === "event") {
        value = e.target.value;
      } else if (method === "value") {
        value = e;
      }
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    };

  const handleSubmit = () => {
    if (!error) {
      let proms = formData.docsOnFile.map((doc) => {
        return submitSupportingDoc(doc.file, claimUid, setProgress);
      });
      Promise.all(proms)
        .then(setPageState({ page: pageState.page, prevPage: pageState.page }))
        .then(setMessage("Files successfully uploaded."))
        .catch((error) => {
          if (error.response.status === 413) {
            setError(
              `One of the attached file uploads was too large. Please upload a smaller version of the file, or contact our team for help.`
            );
          }
        });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <React.Fragment>
        <UploadClaimDocs
          updateFormData={updateFormData}
          formData={formData}
          progress={progress}
          cardTitle="Supporting Documents"
          cardHeaderText={`Upload any relevant documents that can help us understand your issue.
          For example, if you're seeking help with a denied claim, a picture of
          your denial letter would be useful to include. This is not required,
          and you can always add documents later.`}></UploadClaimDocs>
        <Grid container mt={3} justifyContent="right">
          <Button variant="contained" onClick={handleSubmit} sx={{ mt: 7 }}>
            {"Upload all docs"}
          </Button>
        </Grid>
        <Box>
          <Grid container mt={3} justifyContent="left">
            {error && (
              <Grid item justifyContent="left">
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {!error && message && (
              <Grid item justifyContent="left">
                <Alert severity="info">{message}</Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
}

export function ModalBase(props) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 12,
    p: 4,
    color: theme.palette.text.primary,
  };
  let closeAction = props.closeAction;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    <div>
      <Button
        startIcon={props.startIcon}
        variant={props.buttonVariant}
        onClick={handleOpen}>
        {props.buttonText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  );
}

export function UploadSupportingDocsModal(props) {
  let closeAction = props.closeAction;
  let buttonVariant = "text";
  let claim_uid = props.claim_uid;
  let pageState = props.pageState;
  let setPageState = props.setPageState;

  return (
    <ModalBase
      buttonText="Upload Supporting Documents"
      buttonVariant={buttonVariant}
      closeAction={closeAction}>
      <UploadSupportingDocs
        claim_uid={claim_uid}
        pageState={pageState}
        setPageState={setPageState}
      />
    </ModalBase>
  );
}

export default UploadSupportingDocsModal;
