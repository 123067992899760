// External imports
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Internal Imports
import { getAuthHeader, handle401 } from "../utils/auth";
import { BACKEND_URL } from "../utils/config";

// Get regions
export const getRegions = async () => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "resources/regions"].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Get region insurers
export const getRegionInsurers = async (region: string) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "resources/region_insurers", region].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Get insurer plans
export const getInsurerPlans = async (params: URLSearchParams) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "resources/insurer_plans"].join("/"),
    headers: header,
    params: params,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Add new insurer
export const addInsurer = async (fdata: any) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [BACKEND_URL, "resources/insurers"].join("/"),
    headers: header,
    data: fdata,
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        } else if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

// Add new plan
export const addPlan = async (fdata: any) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [BACKEND_URL, "resources/plans"].join("/"),
    headers: header,
    data: fdata,
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        }

        if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

// Get insurer
export const getInsurer = async (id: number) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "resources/insurers", id].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);
  return response.data;
};

// Get digital download static resources
export const getDigitalDownloads = async () => {
  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "static_resources/digital_downloads"].join("/"),
  };

  const response: AxiosResponse = await axios(request_config);
  return response.data;
};

// Get digital download static resources
export const incrementDownloadViewCount = async (uid: string) => {
  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [BACKEND_URL, "static_resources/digital_download"].join("/"),
    data: uid,
  };

  const response: AxiosResponse = await axios(request_config);
  return response.data;
};
