export const API_BASE: string =
  process.env.REACT_APP_RUNTIME_ENV === "production"
    ? "https://persius.org/api"
    : "https://localhost:8443/api";
export const BACKEND_URL: string =
  process.env.REACT_APP_RUNTIME_ENV === "production"
    ? "https://persius.org/api/v1"
    : "https://localhost:8443/api/v1";

export const SOCKET_URL: string =
  process.env.REACT_APP_RUNTIME_ENV === "production"
    ? "wss://persius.org/api/v1/chat"
    : "wss://localhost:8443/api/v1/chat";
