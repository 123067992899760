// External Imports
import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// Internal Imports
import { submitSOB } from "../../api/accounts.ts";

const CoverageInsights = (props) => {
  let coverage = props.coverage;
  return (
    <Card>
      <CardHeader title="Details Summary" style={{ textAlign: "center" }} />
      <Divider />
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell> SOB on File</TableCell>
              {!coverage.uid && (
                <TableCell>
                  <b>False</b>{" "}
                </TableCell>
              )}
              {coverage.uid && coverage.sob_url && (
                <TableCell>
                  <b>True</b>
                </TableCell>
              )}
              {coverage.uid && !coverage.sob_url && (
                <TableCell>
                  <b>False</b>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell> Premium</TableCell>
              {coverage.premium_cost && (
                <TableCell> ${coverage.premium_cost}</TableCell>
              )}
              {!coverage.premium_cost && (
                <TableCell>
                  <b>Unknown</b>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell> Out of Pocket Cost</TableCell>
              {coverage.user_cost && (
                <TableCell> ${coverage.user_cost}</TableCell>
              )}
              {!coverage.user_cost && (
                <TableCell>
                  <b>Unknown</b>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

const SOB = (props) => {
  let coverage = props.coverage;
  return (
    <Card>
      <CardHeader title="Summary of Benefits" style={{ textAlign: "center" }} />
      <Divider />
      <Box>
        {coverage.sob_url && (
          <div>
            <object
              data={coverage.sob_url}
              type="application/pdf"
              width="100%"
              height="800px"
            >
              <a href={coverage.sob_url}> Coverage PDF</a>
            </object>
            {/* <iframe src="{{coverage_url}}" width="100%" height="1500px" ></iframe>--></embed> */}
          </div>
        )}
        {!coverage.sob_url && (
          <Typography mt={8} mb={8} variant="h3" component="h3" align="center">
            Not Uploaded
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export const CoverageDocuments = (props) => {
  let coverage = props.coverage;
  let setCoverageCurrent = props.setCoverageCurrent;
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileSelect = (event) => {
    setError(null);
    setMessage(null);
    setProgress(0);
    setSelectedFile(event.target.files[0]);
  };

  const validFile = (selectedFile) => {
    if (selectedFile && selectedFile.type === "application/pdf") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitSOB = () => {
    let valid = validFile(selectedFile);
    if (valid) {
      submitSOB(selectedFile, setProgress)
        .catch((error) => {
          if (error.response.status === 409) {
            setError(error.response.data.detail);
          }
          if (error.response.status === 413) {
            setError(
              "File too large. Please upload a smaller version of the file."
            );
          }
        })
        .finally(() => {
          setSelectedFile(null);
          if (!error) {
            setMessage("Successfully uploaded SOB.");
          }
          setTimeout(setCoverageCurrent(false), 1000);
        });
    } else {
      setSelectedFile(null);
      setError(
        "There is something wrong with your file selection. Please select a pdf copy of your SOB."
      );
    }
  };

  return (
    <Card>
      <CardHeader
        subheader=""
        title="Coverage Insights"
        titleTypographyProps={{ align: "center" }}
        subheaderTypographyProps={{
          align: "center",
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[700],
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} s={4} md={4}>
            <CoverageInsights coverage={coverage} />
          </Grid>
          <Grid item xs={12} s={8} md={8}>
            <SOB coverage={coverage} />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Grid item>
            {error && <Alert severity="error">{error}</Alert>}{" "}
            {!error && message && <Alert severity="info">{message}</Alert>}
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" component="label">
              Select File
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
            </Button>
            {selectedFile && (
              <Typography mt={1}>Selected: {selectedFile.name}</Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmitSOB}
            >
              Upload
            </Button>
            <Box sx={{ width: "100%" }}>
              {progress > 0 && progress < 100 && (
                <LinearProgress
                  variant="determinate"
                  color="primary"
                  spacing={2}
                  value={progress}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
