// External Imports
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

// Internal Imports

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 6) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: 64,
      mb: 2,
      width: 64,
    },
    children: `${name[0]}`,
  };
}

function updateUser(user) {
  if (user.first_name && user.last_name) {
    user.name = capitalize(user.first_name) + " " + capitalize(user.last_name);
  }

  user.avatar = "";

  if (user.is_staff) {
    user.authLevel = "Staff";
  } else if (user.is_superuser) {
    user.authLevel = "Superuser";
  } else {
    user.authLevel = "Community";
  }

  return user;
}

export const SummaryCard = (props) => {
  const [loaded, setLoaded] = useState(false);
  let user = props.user;

  useEffect(() => {
    if (user.username !== undefined) {
      setLoaded(true);
    }
  }, [user]);

  user = updateUser(user);
  if (loaded) {
    return (
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}>
            <Avatar
              // src={user.avatar}
              {...stringAvatar(user.username)}
            />
            <Typography color="textPrimary" gutterBottom variant="h5">
              {user.username}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {user.name}
            </Typography>
            {user.city && user?.region?.name && (
              <Typography color="textSecondary" variant="body2">
                {`${user.city}, ${user.region.name}`}
              </Typography>
            )}
            {user.city && !user?.region?.name && (
              <Typography color="textSecondary" variant="body2">
                {`${user.city}`}
              </Typography>
            )}
            {!user.city && user?.region?.name && (
              <Typography color="textSecondary" variant="body2">
                {`${user.region.name}`}
              </Typography>
            )}
            <Typography color="textSecondary" variant="body2">
              {user.email}
            </Typography>
            <Chip
              label={user.authLevel}
              color={
                (user.authLevel === "Community" && "secondary") ||
                (user.authLevel === "Staff" && "success") ||
                (user.authLevel === "Claims Admin" && "success") ||
                (user.authLevel === "Superuser" && "success") ||
                "error"
              }
              sx={{
                mt: 2,
              }}
            />
          </Box>
        </CardContent>
        <Divider />
        {/* <CardActions>
      <Button color="primary" fullWidth>
        Upload Avatar Image
      </Button>
    </CardActions> */}
      </Card>
    );
  } else {
    return (
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}>
            <CircularProgress />
          </Box>
        </CardContent>
        <Divider />
      </Card>
    );
  }
};
