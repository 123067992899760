// External Imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

// Internal Imports
import { getRegions } from "../api/resources.ts";
import { handle401 } from "../utils/auth.ts";

export default function CoverageHelpRegionInfo() {
  let params = useParams();
  const [regions, setRegions] = useState([]);
  const [areRegionsLoaded, setAreRegionsLoaded] = useState(false);

  const [error, setError] = useState(null);

  const url_region = params.region.split("-").join(" ");

  useEffect(() => {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      })
      .finally(() => setAreRegionsLoaded(true));
  }, []);

  // TODO: Handle url region that is not in api response. Currently will produce a page error.
  const region = regions.find((region) => region.name === url_region);

  if (typeof region === "undefined") {
    return (
      <Card>
        {" "}
        <Alert severity="error">
          We don't have the region: {url_region} in our system.
        </Alert>
      </Card>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!areRegionsLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <React.Fragment>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />

        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom>
            Self Help Tools: {region.name}
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container spacing={2} mb={4} alignItems="flex-end">
            <Grid
              item
              key="state-specific-external-appeals"
              xs={12}
              sm={12}
              md={12}>
              <Card>
                <CardHeader
                  title="External Appeals"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}>
                    <Typography
                      paragraph={true}
                      variant="body1"
                      color="text.primary">
                      Different states may administer the external review
                      processes for fully insured individual and group commercial
                      health plans in {" "}
                      <Link href="https://www.cms.gov/CCIIO/Resources/Files/external_appeals">different ways</Link>{" "}
                       (see also <Link href="https://www.federalregister.gov/documents/2011/06/24/2011-15890/group-health-plans-and-health-insurance-issuers-rules-relating-to-internal-claims-and-appeals-and">this</Link>).{" "}
                      {region.name} uses the {region.review_process_type}.
                    </Typography>

                    {region.review_process_type ===
                      "HHS Administered Process or Independent Process" && (
                      <Typography
                        paragraph={true}
                        variant="body1"
                        color="text.primary">
                        This means that fully insured plans regulated by your state either utilize an
                        external review process facilitated by the federal
                        government (HHS Administered), or they make use of a
                        separate indepdent review process that meets the same
                        standards. If you determine your plan uses the HHS
                        Administered Process, you may directly file your
                        external appeal electronically or by mail by following
                        the directions
                        <Link href="https://externalappeal.cms.gov/ferpportal/#/home">
                          {" "}
                          here
                        </Link>
                        .
                      </Typography>
                    )}

                    {region.review_process_type !==
                      "HHS Administered Process or Independent Process" && (
                      <Typography
                        paragraph={true}
                        variant="body1"
                        color="text.primary">
                        This means that the set of external review entities used for
                        external appeals is insurer specific, and you must
                        determine where external appeals are to be sent in individual cases by
                        consulting your insurance policy. A good first place to
                        look is in the explanation of benefits (EOB) for a particular claim denial, or 
                        your full policy contract.
                      </Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              key="state-specific-external-resources"
              xs={12}
              sm={12}
              md={12}>
              <Card>
                <CardHeader
                  title="State Specific Resources"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}>
                    <Typography
                      paragraph={true}
                      variant="body1"
                      color="text.primary">
                      One can submit a complaint about a health insurer dispute
                      directly with the {region.name} insurance board at this
                      <Link href={region.insurance_board_complaint_link}>
                        {" "}
                        link
                      </Link>
                      .
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
