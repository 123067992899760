import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";

export const PrivateRoute = (props) => {
  const location = useLocation();
  let isLoggedIn = props.isLoggedIn;

  return isLoggedIn === true ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ path: location.pathname }} />
  );
};
