// External imports
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";

// Icons
import EmailIcon from "@mui/icons-material/Email";

// Internal Imports

export default function Contact() {
  const theme = useTheme();
  const styles = {
    bannerContainer: {
      // backgroundSize: `cover`,
      // backgroundPosition: `center center`,
      backgroundColor: theme.palette.background.paper2,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%239C92AC' fill-opacity='0.2'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      height: "100vh",
      width: "100vw",
      opacity: 0.9,
      filter: "brightness(100%)",
    },
  };
  return (
    <React.Fragment>
      {/* header */}
      {/* <Box
        sx={{
          bgcolor: theme.palette.background.paper2,
          pt: 8,
          pb: 6,
        }}> */}
      <Container
        component="header"
        maxWidth="false"
        style={styles.bannerContainer}
      >
        <Container maxWidth="md">
          <Typography
            mt="25vh"
            component="h1"
            variant="h2"
            align="center"
            color={theme.palette.primary.main}
            p={1}
            sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1, borderColor: 'primary.main', borderWidth: 3}}
            gutterBottom
          >
            Get In Touch
          </Typography>

          <Typography
            mt={8}
            component="h5"
            variant="h5"
            align="center"
            color={theme.palette.text.primary}
            gutterBottom
          >
            We're here to help, and happy to answer any questions you might
            have.
            <br />
            Feel free to reach out{" "}
            <Link
              align="center"
              href="mailto:info@persius.org"
              underline="always"
            >
              by email
            </Link>{" "}
            any time. We'll do our best to answer as soon as possible. <br />
            <br />
            Thank you,
            <br />
            The Persius Team
          </Typography>
        </Container>
      </Container>
      {/* </Box> */}
    </React.Fragment>
  );
}
