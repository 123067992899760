//External Imports
import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

//Internal Imports

export default function Copyright(props) {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      color={theme.palette.text.dark}
      align="center"
      {...props}>
      {"Copyright © "}
      <Link component={RouterLink} to={"/"} color="inherit" href="#">
        Persius
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
