//External Imports
import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

//Internal Imports
import { handle401 } from "../utils/auth.ts";
import {
  getRegions,
  getRegionInsurers,
  addInsurer,
  addPlan,
} from "../api/resources.ts";

function AddInsurerForm() {
  const [region, setRegion] = useState("");
  const [insurer, setInsurer] = useState("");
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);

  // TODO: Just pass regions from parent component.
  function refreshRegions() {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
          setRegion(result.data[0].name);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  useEffect(refreshRegions, []);

  const updateRegion = (e) => {
    setError(null);
    setRegion(e.target.value);
  };

  const updateInsurer = (e) => {
    setError(null);
    setInsurer(e.target.value);
  };

  const validInsurer = (insurer) => {
    if (region.length > 0 && insurer.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const submitInsurer = () => {
    let valid = validInsurer(insurer);
    if (valid) {
      addInsurer({ region: region, insurer: insurer }).catch((error) => {
        setAlert({ message: error, type: "error" });
      });
      setAlert({ message: "Insurer successfully added.", type: "success" });
    } else {
      setAlert({
        message: "Please enter a region and insurer name.",
        type: "error",
      });
    }
  };

  if (!error) {
    return (
      <React.Fragment>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={6}>
          Add a New Insurer
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="State or Region"
              name="region"
              InputLabelProps={{ shrink: true }}
              onChange={updateRegion}
              required
              select
              SelectProps={{ native: true }}
              value={region}
              variant="outlined">
              <option key="no_region" value="" disabled>
                Select a Region
              </option>
              {regions.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Insurer Name"
              name="insurer"
              value={insurer}
              onChange={updateInsurer}
              variant="outlined"
            />
          </Grid>
          {alert && (
            <Grid item justifyContent="center">
              <Alert severity={alert.type}>{alert.message}</Alert>
            </Grid>
          )}
          <Divider />
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={submitInsurer}>
                Add Insurer
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={6}>
          Add a New Insurer
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function AddPlanForm() {
  const [region, setRegion] = useState("");
  const [insurer, setInsurer] = useState("");
  const [plan, setPlan] = useState("");
  const [regions, setRegions] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);

  function refreshRegions() {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  function refreshInsurers() {
    if (region) {
      getRegionInsurers(region)
        .then((result) => {
          if (result?.data?.length > 0) {
            setInsurers(result.data);
            setInsurer(result.data[0].name);
          } else {
            setInsurers([]);
            setInsurer("");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    }
  }

  useEffect(refreshRegions, []);
  useEffect(refreshInsurers, [region]);

  const updateRegion = (e) => {
    setError(null);
    setRegion(e.target.value);
  };

  const updateInsurer = (e) => {
    setError(null);
    setInsurer(e.target.value);
  };

  const updatePlan = (e) => {
    setError(null);
    setPlan(e.target.value);
  };

  const validPlan = (plan) => {
    if (region.length > 0 && insurer.length > 0 && plan.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const submitPlan = () => {
    let valid = validPlan(plan);
    if (valid) {
      addPlan({ region: region, insurer: insurer, plan: plan }).catch(
        (error) => {
          setAlert({ message: error, type: "error" });
        }
      );
      setAlert({ message: "Plan successfully added.", type: "success" });
    } else {
      setAlert({
        message: "Please enter a region, insurer and plan name.",
        type: "error",
      });
    }
  };

  if (!error) {
    return (
      <React.Fragment>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={6}>
          Add a New Plan
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="State or Region"
              name="region"
              InputLabelProps={{ shrink: true }}
              onChange={updateRegion}
              required
              select
              SelectProps={{ native: true }}
              value={region}
              variant="outlined">
              <option key="no_region" value="" disabled>
                Select a Region
              </option>
              {regions.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Insurer"
              name="insurer"
              onChange={updateInsurer}
              required
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              value={insurer}
              variant="outlined">
              <option key="no_insurers_logged" value="" disabled>
                Select an Insurer
              </option>
              {insurers.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Plan Name"
              name="plan"
              value={plan}
              onChange={updatePlan}
              variant="outlined"
            />
          </Grid>
          {alert && (
            <Grid item justifyContent="center">
              <Alert severity={alert.type}>{alert.message}</Alert>
            </Grid>
          )}
          <Divider />
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={submitPlan}>
                Add Plan
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={6}>
          Add a New Plan
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function BasicModal(props) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 12,
    p: 4,
    color: theme.palette.text.primary,
  };
  let closeAction = props.closeAction;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    <div>
      <Button
        startIcon={props.startIcon}
        variant={props.buttonVariant}
        onClick={handleOpen}>
        {props.buttonText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  );
}

export function AddInsurerModal(props) {
  let closeAction = props.closeAction;
  let buttonVariant = "text";
  return (
    <BasicModal
      buttonText="Add Insurer"
      buttonVariant={buttonVariant}
      closeAction={closeAction}>
      <AddInsurerForm />
    </BasicModal>
  );
}

export function AddPlanModal(props) {
  let closeAction = props.closeAction;
  return (
    <BasicModal buttonText="Add Plan" closeAction={closeAction}>
      <AddPlanForm />
    </BasicModal>
  );
}
