import { BACKEND_URL } from "../utils/config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const enrollEmail = async (email: string) => {
  // Assert email and password are not empty
  if (!(email.length > 0)) {
    throw new Error("Please enter an email address");
  }

  const formData = new FormData();

  // Instantiate form
  formData.append("email_address", email);

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [BACKEND_URL, "emails"].join("/"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};