//External Imports
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

//Internal Imports
import Copyright from "./Copyright.js";
import Social from "./Social.js";

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        py: 3,
        mt: "auto",
      }}>
      <Container align="center" maxWidth="sm">
        <Social />
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </Box>
  );
}
