// External Imports
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Internal Imports
import {
  getDigitalDownloads,
  incrementDownloadViewCount,
} from "../api/resources.ts";
import { CardWrapper } from "../components/cards";

function openInNewTab(url) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function ResourceCard(props) {
  const theme = useTheme();
  const val = props.val;

  return (
    <CardWrapper border={true} content={false}>
      <Box sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
          <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
            <ListItemText
              sx={{
                py: 0,
                mt: 0.45,
                mb: 0.45,
              }}
              primary={
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ color: theme.palette.text.color }}
                >
                  {val.val}
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{ color: theme.palette.text.primary, mt: 0.25 }}
                >
                  {val.label}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </CardWrapper>
  );
}

function ResourceGrid(props) {
  const resource = props.resource;
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} xs={12}>
        <ResourceCard
          val={{
            val: resource.views,
            label: "Views",
          }}
        ></ResourceCard>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ResourceCard
          val={{
            val: resource.version,
            label: "Version",
          }}
        ></ResourceCard>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ResourceCard
          val={{
            val: resource.release_date,
            label: "Release Date",
          }}
        ></ResourceCard>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ResourceCard
          val={{
            val: resource.last_modified_date,
            label: "Last Modified Date",
          }}
        ></ResourceCard>
      </Grid>
    </Grid>
  );
}

function DownloadCard(props) {
  const theme = props.theme;
  const resource = props.resource;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={6}>
            <Typography
              variant="h6"
              color={theme.palette.text.dark}
              style={{ fontWeight: 600 }}
              gutterBottom
            >
              Resource
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.dark}
              gutterBottom
            >
              {resource.name}
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.dark}
              mt={3}
              style={{ fontWeight: 600 }}
              gutterBottom
            >
              Description
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.dark}
              gutterBottom
            >
              {resource.description}
            </Typography>
          </Grid>
          <Grid item lg={8} md={6} xs={6} mt={10}>
            <ResourceGrid theme={theme} resource={resource}></ResourceGrid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          size="small"
          onClick={(e) => {
            e.preventDefault();
            incrementDownloadViewCount(resource.uid);
            openInNewTab(resource.link)
          }}
        >
          View Resource
        </Button>{" "}
      </CardActions>
    </Card>
  );
}

export default function Downloads() {
  const theme = useTheme();
  const [error, setError] = useState(null);
  const [resources, setResources] = useState([]);
  const [areResourcesLoaded, setAreResourcesLoaded] = useState(false);

  useEffect(() => {
    getDigitalDownloads()
      .then((result) => {
        setResources(result);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setAreResourcesLoaded(true));
  }, []);

  if (!error) {
    return (
      <React.Fragment>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color={theme.palette.text.primary}
          mt={5}
          mx={5}
        >
          Digital Resources
        </Typography>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
          justifyContent="center"
        >
          <Container maxWidth={false}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item lg={8} md={8} xs={12}>
                <Card>
                  {areResourcesLoaded &&
                    resources.map((resource) => (
                      <React.Fragment key={resource.name}>
                        <Divider></Divider>
                        <DownloadCard
                          theme={theme}
                          resource={resource}
                        ></DownloadCard>
                      </React.Fragment>
                    ))}
                  {!areResourcesLoaded && (
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CircularProgress
                            variant="indeterminate"
                            value={40}
                            style={{ color: theme.palette.primary.main }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color={theme.palette.text.primary}
          mt={5}
          mx={5}
        >
          Digital Resources
        </Typography>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
          justifyContent="center"
        >
          <Container maxWidth={false}>
            <Alert severity="error">{error}</Alert>{" "}
          </Container>{" "}
        </Box>{" "}
      </React.Fragment>
    );
  }
}
