//External Imports
import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import CoverageSummary from "../components/CoverageSummary";
import ClaimsSummary from "../components/ClaimsSummary";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

//Internal Imports
import { handle401 } from "../utils/auth.ts";
import { getUser } from "../api/accounts.ts";
import { SummaryCard } from "../components/account/SummaryCard";

export default function AccountHome() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);

  function refreshUser() {
    setError(null);
    getUser()
      .then((result) => {
        setUser(result);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  useEffect(refreshUser, []);
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          py: 8,
          display: "flex-end",
        }}
        justifyContent="center"
      >
        <Grid
          container
          justifyContent="center"
          direction="row"
          sx={{ display: "flex" }}
        >
          <Grid item lg={4} md={4} s={8} xs={12} mb={2}>
            <Container maxWidth={false}>
              <Typography
                component="h4"
                variant="h4"
                align="center"
                color={theme.palette.text.primary}
                mb={2}
              >
                Account Info
              </Typography>
              {error && <Alert severity="error">{error.message}</Alert>}
              {!error && <SummaryCard user={user}></SummaryCard>}
            </Container>
          </Grid>
          <Grid container item lg={6} md={6} s={8} xs={12} spacing={0}>
            <Container maxWidth={false}>
              <Typography
                component="h4"
                variant="h4"
                align="center"
                color={theme.palette.text.primary}
                mb={2}
              >
                Quick Start
              </Typography>
              <Grid
                container
                row
                spacing={3}
                // alignItems="center"
                justifyContent="center"
              >
                {/* Quickstart Box 1 */}
                <Grid item>
                  <Card variant="outlined" sx={{ my: { xs: 3, md: 6 } }}>
                    <CardHeader
                      title="Get Help"
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                      }}
                    ></CardHeader>
                    <CardContent>
                      {" "}
                      <Grid
                        container
                        item
                        direction="column"
                        sx={{ display: "flex" }}
                      >
                        <Grid item lg={3} md={5} xs={12}>
                          <Button
                            type="submit"
                            onClick={() => navigate("/claims/submit-claim")}
                            sx={{ mt: 2, mb: 0 }}
                          >
                            Submit Coverage Issue
                          </Button>
                        </Grid>
                        <Grid item lg={3} md={5} xs={12}>
                          <Button
                            type="submit"
                            onClick={() => navigate("/contact")}
                            sx={{ mt: 2, mb: 0 }}
                          >
                            Contact Us
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Quickstart Box 2 */}
                <Grid item>
                  <Card variant="outlined" sx={{ my: { xs: 3, md: 6 } }}>
                    <CardHeader
                      title="Get Information"
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                      }}
                    ></CardHeader>
                    <CardContent>
                      {" "}
                      <Grid container item direction="column">
                        <Grid item lg={3} md={5} xs={12}>
                          {" "}
                          <Button
                            type="submit"
                            onClick={() => navigate("/resources/self-help-tools")}
                            sx={{ mt: 2, mb: 0 }}
                          >
                            Learn Your Rights
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography
            component="h4"
            variant="h4"
            align="center"
            color={theme.palette.text.primary}
            mt={0}
            mb={5}
          >
            Coverage Status
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <CoverageSummary />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              {error && <Alert severity="error">{error.message}</Alert>}
              {!error && <ClaimsSummary user={user} />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}
