// External imports
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

// Icons
import NoteIcon from "@mui/icons-material/Note";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useWindowDimensions } from "../utils/window";

// Internal Imports
import family from "../images/family.webp";

export default function Services() {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();

  // Hack because I suck with flexbox
  const bannerImgOffset = width > 500 ? "35vh" : "5vh";

  const styles = {
    bannerContainer: {
      // backgroundSize: `cover`,
      // backgroundPosition: `center center`,
      backgroundColor: theme.palette.background.paper2,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%239C92AC' fill-opacity='0.2'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      height: "100vh",
      width: "100vw",
      opacity: 0.9,
      filter: "brightness(100%)",
    },
  };
  const navigate = useNavigate();
  const facets = [
    {
      title: "Denied Claims",
      icon: (
        <NoteIcon
          style={{ fill: theme.palette.primary.main }}
          fontSize="large"
        />
      ),
      description: ` We help people get coverage for care guaranteed by their plans.
      We can help evaluate your denied claims, and fight for coverage when denials are inappropriate.`,
    },
    {
      title: "Resources",
      icon: (
        <MenuBookIcon
          style={{ fill: theme.palette.primary.main }}
          fontSize="large"
        />
      ),
      description: `We provide resources that help people navigate issues on their own, or seek other third party help.`,
    },
    {
      title: "Investigations",
      icon: (
        <MenuBookIcon
          style={{ fill: theme.palette.primary.main }}
          fontSize="large"
        />
      ),
      description: `We conduct data driven investigations, and report on barriers, prevalent behaviors, policy, and perverse incentives plaguing U.S. health insurance.`,
    },
    // {
    //   title: "Reviews",
    //   icon: (
    //     <RateReviewIcon
    //       style={{ fill: theme.palette.primary.main }}
    //       fontSize="large"
    //     />
    //   ),
    //   description: `We provide a space for people to leave informative, factual reviews of insurers and plans. Our hope is reviews will empower the U.S. populace to collectively act on persistent insurer issues
    //   through their purchasing power, and equip the public with key information that is typically difficult to access.`,
    // },
    // {
    //   title: "Coverage Insights",
    //   icon: (
    //     <InsightsIcon
    //       style={{ fill: theme.palette.primary.main }}
    //       fontSize="large"
    //     />
    //   ),
    //   description: `We provide automated analyses of your insurance plan benefit and coverage documentation, and use our insights to inform you of coverage and cost calculations for various circumstances. We can also use these insights to automate help with your claims processing.`,
    // },
  ];
  return (
    <React.Fragment>
      {/* header */}
      <Container
        component="header"
        maxWidth="false"
        style={styles.bannerContainer}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          justify="center"
        >
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              variant="h2"
              align="center"
              mt={"25vh"}
              color={theme.palette.text.primary}
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "3px",
                textDecorationColor: theme.palette.primary.dark,
                textUnderlineOffset: "15px",
              }}
            >
              <b>What We Do</b>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              mt={"5vh"}
              color={theme.palette.text.primary}
            >
              <b>
                We build free
                digital tools
                to help <br /> people fight health insurance denials.
              </b>
            </Typography>
          </Grid>
          <Grid maxWidth="false" item xs={12} md={6} align="center">
            <Box
              mt={bannerImgOffset}
              component="img"
              src={family}
              alt={"A happy family."}
              sx={{
                width: "70%",
                border: `3px solid` + theme.palette.primary.dark,
                borderRadius: "10px 10px 10px 10px",
                backgroundSize: `contain`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          pt: "10vh",
          pb: "0vh",
          mx: "10vw",
        }}
      >
        {facets.map((facet) => {
          return (
            <Container key={facet.title}>
              <Box
                sx={{
                  bgcolor: theme.palette.background.default,
                  pt: 4,
                  pb: 6,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={0}>
                    {facet.icon}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      component="h3"
                      variant="h4"
                      align="left"
                      color={theme.palette.primary.main}
                      gutterBottom
                    >
                      {facet.title}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="h5"
                  align="left"
                  justifyContent="left"
                  color={theme.palette.text.dark}
                  paragraph
                >
                  {facet.description}
                </Typography>
              </Box>
            </Container>
          );
        })}
      </Box>
      <Container
        component="main"
        maxWidth={false}
        style={{
          backgroundColor: theme.palette.secondary.main,
          marginTop: 200,
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color={theme.palette.text.paper}
          gutterBottom
        >
          <br />
          Make a Free Account to Access Our Services
        </Typography>
        <Box
          sx={{
            marginTop: 5,
            marginBottom: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="p"
            align="center"
            color={theme.palette.text.paper}
          >
            All of the services we provide are free for individuals. <br />
            You just need to make an account. <br /> <br />
            Questions? Email{" "}
            <Link
              align="center"
              color={theme.palette.primary.main}
              href="mailto:info@persius.org"
              underline="hover"
            >
              info@persius.org
            </Link>
          </Typography>
          <Box noValidate sx={{ mt: 3, mb: 5 }} maxWidth>
            <Grid container item xs={12} spacing={1}>
              <Button
                type="submit"
                onClick={() => {
                  navigate("/signup");
                }}
                fullWidth
                variant="contained"
              >
                Create Account
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}
