//External Imports
import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CoveragePage from "./CoveragePage";
import IssuePage from "./IssuePage";
import SummaryPage from "./SummaryPage";
import UploadClaimDocs from "./UploadClaimDocs";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";

//Internal Imports
import { handle401 } from "../../utils/auth.ts";
import { getCoverage } from "../../api/accounts.ts";
import { submitClaim, submitSupportingDoc } from "../../api/claims.ts";
import {
  getInsurerPlans,
  getRegionInsurers,
  getRegions,
} from "../../api/resources.ts";

const steps = [
  "Coverage Information",
  "Issue Questionairre",
  "Issue Description",
  "Supporting Documents",
];

export default function ClaimForm() {
  let location = useLocation();
  const navigate = useNavigate();
  const today = new Date();
  const [regions, setRegions] = useState([]);
  const [areRegionsLoaded, setAreRegionsLoaded] = useState(false);
  const [areInsurersLoaded, setAreInsurersLoaded] = useState(false);
  const [arePlansLoaded, setArePlansLoaded] = useState(false);
  const [insurers, setInsurers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [coverage, setCoverage] = useState({});
  const [coverageLoaded, setCoverageLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  const defaultForm = {
    region: "",
    insurer: "",
    plan: "",
    member_id: "",
    employer_sponsored: true,
    urgent: true,
    care_date: today.toISOString().substring(0, 10),
    initially_denied: "YES",
    denial_reason: "NMN",
    internally_appealed: "YES",
    externally_appealed: "YES",
    subject: "",
    description: "",
    docsOnFile: [],
  };

  // Form data state
  const [formData, setFormData] = useState(defaultForm);

  // Refresh state on new navigation
  useEffect(() => {
    setActiveStep(0);
    setError("");
  }, [location.key]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = useState("");

  const updateFormData =
    (input, method = "event") =>
    (e) => {
      setError("");
      let value = "";
      if (method === "event") {
        value = e.target.value;
      } else if (method === "value") {
        value = e;
      }
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    };

  useEffect(() => {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
          if (coverage?.region !== null && coverage?.region !== undefined) {
            updateFormData("region", "value")(coverage?.region?.name);
          } else {
            updateFormData("region", "value")("");
          }
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setAreRegionsLoaded(true);
      });
  }, [setRegions, setAreRegionsLoaded, coverageLoaded, coverage]);

  useEffect(() => {
    let region = formData.region;
    if (region.length > 0) {
      getRegionInsurers(region)
        .then((result) => {
          if (result?.data?.length > 0) {
            setInsurers(result.data);
            if (coverage?.insurer !== null && coverage?.insurer !== undefined) {
              updateFormData(
                "insurer",
                "value"
              )(coverage?.insurer?.display_name);
            } else {
              updateFormData("insurer", "value")("");
            }
          } else {
            setInsurers([]);
            updateFormData("insurer", "value")("");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        })
        .finally(() => setAreInsurersLoaded(true));
    } else {
      setInsurers([]);
      updateFormData("insurer", "value")("");
      setAreInsurersLoaded(true);
    }
  }, [formData.region, setInsurers, setAreInsurersLoaded, coverage.insurer]);

  useEffect(() => {
    let region = formData.region;
    let insurer = formData.insurer;
    if (region.length > 0 && insurer.length > 0) {
      let params = new URLSearchParams({
        insurer_name: insurer,
        region: region,
      });
      getInsurerPlans(params)
        .then((result) => {
          if (result?.data?.plans?.length > 0) {
            setPlans(result.data.plans);
            if (coverage?.plan !== null && coverage?.plan !== undefined) {
              updateFormData("plan", "value")(coverage?.plan?.display_name);
            } else {
              updateFormData("plan", "value")("");
            }
          } else {
            setPlans([]);
            updateFormData("plan", "value")("");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        })
        .finally(() => setArePlansLoaded(true));
    } else {
      setPlans([]);
      updateFormData("plan", "value")("");
      setArePlansLoaded(true);
    }
  }, [
    regions,
    formData.region,
    formData.insurer,
    setPlans,
    setArePlansLoaded,
    coverage.plan,
  ]);

  function validateRequiredFields(requiredFields) {
    let errorMessage = "";
    requiredFields.forEach((x, i) => {
      if (formData[x].length === 0) {
        errorMessage = `Please fill out all fields.`;
        setError(errorMessage);
      }
    });
    return errorMessage;
  }

  function validateStep(step) {
    let error = "";
    let requiredFields = [];
    switch (step) {
      case 0:
        requiredFields = ["region", "insurer", "plan", "member_id"];
        error = validateRequiredFields(requiredFields);
        return error;
      case 1:
        requiredFields = [];
        error = validateRequiredFields(requiredFields);
        return error;
      case 2:
        requiredFields = ["subject", "description"];
        error = validateRequiredFields(requiredFields);
        return error;
      case 3:
        requiredFields = [];
        error = validateRequiredFields(requiredFields);
        return error;
      default:
        return error;
    }
  }

  const handleNext = (activeStep) => () => {
    let error = validateStep(activeStep);
    if (!error) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmit = () => {
    let error = validateStep(activeStep);
    if (!error) {
      let { docsOnFile, ...claimData } = formData;
      submitClaim(claimData)
        .then((result) => {
          let claim_uid = result.data.uid;
          let proms = formData.docsOnFile.map((doc) => {
            return submitSupportingDoc(doc.file, claim_uid, setProgress);
          });
          return Promise.all(proms);
        })
        .catch((error) => {
          if (error.response.status === 413) {
            setError(
              `Your claim was submitted, but one of the attached file uploads was too large. Please upload a smaller version of the file by going to your claims page
              and clicking "Upload Supporting Documents"`
            );
          }
        });
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setError("");
    setActiveStep(activeStep - 1);
  };

  function getUserCoverage() {
    getCoverage()
      .then((result) => {
        if (result.uid) {
          setCoverage(result);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else if (error?.response?.status === 404) {
          setCoverage({ member_id: "" });
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setCoverageLoaded(true);
      });
  }
  useEffect(getUserCoverage, []);

  // Handle reflecting coverage by default for other fields
  useEffect(() => {
    updateFormData("member_id", "value")(coverage?.member_id || "");
    if (
      coverage?.employer_sponsored !== null &&
      coverage?.employer_sponsored !== undefined
    ) {
      updateFormData(
        "employer_sponsored",
        "value"
      )(coverage?.employer_sponsored);
    }
  }, [coverageLoaded, coverage]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CoveragePage
            updateFormData={updateFormData}
            formData={formData}
            regions={regions}
            areRegionsLoaded={areRegionsLoaded}
            areInsurersLoaded={areInsurersLoaded}
            arePlansLoaded={arePlansLoaded}
            insurers={insurers}
            setInsurers={setInsurers}
            plans={plans}
            setPlans={setPlans}
          />
        );
      case 1:
        return (
          <IssuePage updateFormData={updateFormData} formData={formData} />
        );
      case 2:
        return (
          <SummaryPage updateFormData={updateFormData} formData={formData} />
        );
      case 3:
        return (
          <UploadClaimDocs
            updateFormData={updateFormData}
            formData={formData}
            progress={progress}
          ></UploadClaimDocs>
        );

      default:
        throw new Error("Unknown step");
    }
  }
  // hack
  var orientation = "horizontal";
  if (window.innerWidth < 720) {
    orientation = "vertical";
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Card
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 } }}
        >
          <CardHeader
            title="Denied Claim Submission"
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{
              align: "center",
            }}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[700],
            }}
          />
          <CardContent>
            <Stepper
              activeStep={activeStep}
              sx={{ pt: 3, pb: 5 }}
              orientation={orientation}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Your claim has been submitted.
                  </Typography>
                  <Typography variant="subtitle1">
                    Our team will now be reviewing and processing your claim so
                    we can help you with next steps. We'll send you updates via
                    email, and also provide them on your claims home page.
                  </Typography>
                  <Box>
                    <Grid container mt={3} justifyContent="left">
                      {error && (
                        <Grid item justifyContent="left">
                          <Alert severity="error">{error}</Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={
                        activeStep === steps.length - 1
                          ? handleSubmit
                          : handleNext(activeStep)
                      }
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1
                        ? "Submit Claim"
                        : "Next"}
                    </Button>
                  </Box>
                  <Box>
                    <Grid container mt={3} justifyContent="left">
                      {error && (
                        <Grid item justifyContent="left">
                          <Alert severity="error">{error}</Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </CardContent>
        </Card>
        <Button
          type="submit"
          variant="contained"
          onClick={() => navigate("/claims")}
          sx={{ mt: 3, ml: 3 }}
        >
          Return to All Claims
        </Button>
      </Container>
    </React.Fragment>
  );
}
