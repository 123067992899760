// External Imports
import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { Box, Container, Grid, Typography } from "@mui/material";

// Internal Imports
import { CoverageDocuments } from "../components/account/CoverageDocuments";
import { EditCoverage } from "../components/account/EditCoverage";
import { handle401 } from "../utils/auth.ts";
import { getUser, getCoverage } from "../api/accounts.ts";

export default function Coverage() {
  const [user, setUser] = useState({});
  const [coverage, setCoverage] = useState({});
  const [coverageCurrent, setCoverageCurrent] = useState(false);
  const [error, setError] = useState(null);

  function refreshUser() {
    getUser()
      .then((result) => {
        setUser(result);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  function refreshCoverage() {
    getCoverage()
      .then((result) => {
        setCoverage(result);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401();
        } else if (error?.response?.status === 404) {
          setCoverage({});
        } else {
          setError(error);
        }
      })
      .finally(setCoverageCurrent(true));
  }

  useEffect(refreshUser, []);
  useEffect(refreshCoverage, [coverageCurrent]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}>
      <Container maxWidth="lg">
        <Typography sx={{ mb: 3 }} variant="h4" align="center">
          {user.username}'s Insurance Coverage
        </Typography>
        {error && (
          <Grid item justifyContent="center">
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        {!error && (
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <EditCoverage
                user={user}
                coverage={coverage}
                setCoverageCurrent={setCoverageCurrent}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CoverageDocuments
                coverage={coverage}
                setCoverageCurrent={setCoverageCurrent}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
