// External imports
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Internal Imports
import { getAuthHeader, handle401 } from "../utils/auth";
import { BACKEND_URL } from "../utils/config";

// Get user
export const getUser = async () => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "users/me"].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Edit Profile
export const editUser = async (fdata: any) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [BACKEND_URL, "users/edit_user"].join("/"),
    data: fdata,
    headers: header,
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        }

        if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

// Get all user coverages
export const getCoverages = async () => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "accounts/coverages"].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Get current user coverage
export const getCoverage = async () => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "get",
    url: [BACKEND_URL, "accounts/coverages/current"].join("/"),
    headers: header,
  };

  const response: AxiosResponse = await axios(request_config);

  return response.data;
};

// Create new user coverage
export const createCoverage = async (fdata: any) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [BACKEND_URL, "accounts/coverages"].join("/"),
    headers: header,
    data: fdata,
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        }

        if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

// Edit existing user coverage
export const editCoverage = async (fdata: any) => {
  // Declare auth header
  let header = getAuthHeader();

  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [BACKEND_URL, "accounts/coverages"].join("/"),
    headers: header,
    data: fdata,
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        }

        if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

// Submit SOB
export const submitSOB = async (selectedFile: any, setProgress: any) => {
  // Declare auth header
  let header = getAuthHeader();

  const formData = new FormData();

  // Instantiate form
  formData.append("file", selectedFile);

  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [BACKEND_URL, "accounts/upload_sob"].join("/"),
    data: formData,
    headers: Object.assign({ "Content-Type": "multipart/form-data" }, header),
    onUploadProgress: function (progressEvent) {
      if (progressEvent.total) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      }
    },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;
    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status === 401) {
          handle401();
        }

        if (
          error.response.status !== 401 &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          throw error;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};
