//External Imports
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

//Internal Imports

export default function CoveragePage({
  updateFormData,
  formData,
  regions,
  areRegionsLoaded,
  areInsurersLoaded,
  arePlansLoaded,
  insurers,
  setInsurers,
  plans,
  setPlans,
}) {
  const updateRegion = (e) => {
    updateFormData("region")(e);
    updateFormData("insurer", "value")("");
    setInsurers([]);
    updateFormData("plan", "value")("");
    setPlans([]);
  };

  const updateInsurer = (e) => {
    updateFormData("insurer")(e);
    updateFormData("plan", "value")("");
    setPlans([]);
  };

  const updatePlan = (e) => {
    updateFormData("plan")(e);
  };

  const updateMemberID = (e) => {
    updateFormData("member_id")(e);
  };

  if (!areRegionsLoaded || !areInsurersLoaded || !arePlansLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <React.Fragment>
        <Typography variant="h6" mb={4} gutterBottom>
          Coverage Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={8}>
            <TextField
              fullWidth
              label="State/Region/Province"
              name="select_state_or_region"
              InputLabelProps={{ shrink: true }}
              onChange={updateRegion}
              select
              SelectProps={{ native: true }}
              value={formData.region}
              variant="outlined">
              <option key="no_region" value="" disabled>
                No Region Selected
              </option>
              {regions.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Insurer"
              name="select_insurer"
              onChange={updateInsurer}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              value={formData.insurer}
              variant="outlined">
              <option key="no_insurers_logged" value="" disabled>
                No Insurer Selected
              </option>
              {insurers.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Plan"
              name="select_plan"
              onChange={updatePlan}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              value={formData.plan}
              variant="outlined">
              <option key="no_plans_logged" value="" disabled>
                No Plan Selected
              </option>
              {plans.map((option) => (
                <option key={option.uid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={8} sm={8}>
            <TextField
              required
              id="member_id"
              name="member_id"
              label="Member ID"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData.member_id}
              onChange={updateMemberID}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
