// External Imports
import React, { forwardRef, } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

// constant
const headerSX = {
    "& .MuiCardHeader-action": { mr: 0 },
  };
  
  const MainCard = forwardRef(
    (
      {
        border = true,
        boxShadow,
        children,
        content = true,
        contentClass = "",
        contentSX = {},
        darkTitle,
        secondary,
        shadow,
        sx = {},
        title,
        ...others
      },
      ref
    ) => {
      const theme = useTheme();
  
      return (
        <Card
          ref={ref}
          {...others}
          sx={{
            border: border ? "1px solid" : "none",
            borderColor: theme.palette.text.primary + 75,
            ":hover": {
              boxShadow: boxShadow
                ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
                : "inherit",
            },
            ...sx,
          }}>
          {/* card header and action */}
          {!darkTitle && title && (
            <CardHeader sx={headerSX} title={title} action={secondary} />
          )}
          {darkTitle && title && (
            <CardHeader
              sx={headerSX}
              title={<Typography variant="h3">{title}</Typography>}
              action={secondary}
            />
          )}
  
          {/* content & header divider */}
          {title && <Divider />}
  
          {/* card content */}
          {content && (
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          )}
          {!content && children}
        </Card>
      );
    }
  );
  
  MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.object,
    ]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.object,
    ]),
  };
  
  // styles
  export const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    "&:after": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      background: `linear-gradient(210.04deg, ${theme.palette.primary.light} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
      borderRadius: "50%",
      top: -30,
      right: -180,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      background: `linear-gradient(140.9deg, ${theme.palette.primary.dark} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
      borderRadius: "50%",
      top: -160,
      right: -130,
    },
  }));
  