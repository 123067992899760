import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";

// Internal Imports
import Image from "../images/NorthernLights1000.webp";

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
    height: "100vh",
    width: "100vw",
    opacity: 0.85,
    filter: "brightness(90%)",
  },
};

export default function PageNotFound() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Paper
        style={styles.paperContainer}
        component={Stack}
        direction="column"
        justifyContent="center"
        sx={{ mb: 0, mt: -1 }}>
        <Typography color="inherit" align="center" variant="h1" marked="center">
          404
        </Typography>
        <Typography color="inherit" align="center" variant="p" marked="center">
          This is the end of the road. Enjoy the scenery, then head back to
          charted territory.
        </Typography>
      </Paper>
    </React.Fragment>
  );
}
