//External Imports
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

//Internal Imports
const UploadedFilesCard = ({
  updateFormData,
  formData,
  progress,
  cardTitle,
  cardHeaderText,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleFileSelect = (event) => {
    setError(null);
    setMessage(null);
    setSelectedFile(event.target.files[0]);
  };

  const validFile = (selectedFile) => {
    if (selectedFile === null) {
      return false;
    }
    if (
      selectedFile.type === "application/pdf" ||
      selectedFile.type === "text/plain" ||
      selectedFile.type === "application/octet-stream" ||
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  };

  function addDoc(selectedFile) {
    updateFormData(
      "docsOnFile",
      "value"
    )([
      ...formData.docsOnFile,
      {
        name: selectedFile.name,
        file: selectedFile,
      },
    ]);
    return true;
  }

  const handleAdd = () => {
    let valid = validFile(selectedFile);
    if (valid) {
      addDoc(selectedFile);
      setSelectedFile(null);
      setMessage("Successfully added document to be uploaded.");
    } else {
      setSelectedFile(null);
      setError("There is something wrong with your file selection.");
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" mb={4} gutterBottom>
        {cardTitle}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {cardHeaderText}
        </Grid>
      </Grid>
      <Box mt={3}>
        {formData.docsOnFile.length > 0 && (
          <Typography mt={2} sx={{ fontWeight: "bold", m: 1 }}>
            Files added:
          </Typography>
        )}
        <List>
          {formData.docsOnFile.map((item) => {
            return <ListItem key={item.name}>{item.name}</ListItem>;
          })}
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          p: 0,
        }}>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            p: 0,
          }}>
          <Grid item>
            <Button color="primary" variant="contained" component="label">
              Select File
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
            </Button>
            {selectedFile && (
              <React.Fragment>
                <Typography mt={1} sx={{ fontWeight: "bold" }}>
                  Selected:
                </Typography>
                <Typography>{selectedFile.name}</Typography>
              </React.Fragment>
            )}
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={handleAdd}>
              Add File
            </Button>
            <Box sx={{ width: "100%" }}>
              {progress > 0 && progress < 100 && (
                <LinearProgress
                  variant="determinate"
                  color="primary"
                  spacing={2}
                  value={progress}
                />
              )}
            </Box>
          </Grid>
          <Grid item>
            {error && <Alert severity="error">{error}</Alert>}{" "}
            {!error && message && <Alert severity="info">{message}</Alert>}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%" }}>
        {progress > 0 && progress < 100 && (
          <LinearProgress
            variant="determinate"
            color="primary"
            spacing={2}
            value={progress}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default function UploadClaimDocs({
  updateFormData,
  formData,
  progress,
  cardTitle,
  cardHeaderText,
}) {
  return (
    <UploadedFilesCard
      updateFormData={updateFormData}
      formData={formData}
      progress={progress}
      cardTitle={cardTitle}
      cardHeaderText={cardHeaderText}></UploadedFilesCard>
  );
}
