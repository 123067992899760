//External Imports
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

//Internal Imports
import { getCoverages } from "../api/accounts.ts";
import { handle401 } from "../utils/auth.ts";

export default function CoverageSummary(props) {
  const navigate = useNavigate();
  const [coverages, setCoverages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getCoverages()
      .then((result) => {
        if (result) {
          setCoverages(result);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }, []);

  if (!error) {
    return (
      <Card {...props}>
        <CardHeader
          title="Coverage"
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{
            align: "center",
          }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <CardContent>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Region</TableCell>
                  <TableCell>Insurer</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Member ID</TableCell>
                  <TableCell>Insured</TableCell>
                  <TableCell>Start date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Employer Sponsored</TableCell>
                  <TableCell>Total Premium</TableCell>
                  <TableCell>Out of Pocket Premium</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coverages.map((coverage) => (
                  <TableRow hover key={coverage?.uid}>
                    <TableCell>{coverage?.region.name}</TableCell>
                    <TableCell>{coverage?.insurer?.name}</TableCell>
                    <TableCell>{coverage?.plan?.name}</TableCell>
                    <TableCell>{coverage?.member_id}</TableCell>
                    <TableCell>
                      {coverage?.user?.first_name +
                        " " +
                        coverage?.user?.last_name}
                    </TableCell>
                    <TableCell>{coverage?.effective_date}</TableCell>
                    <TableCell>
                      {coverage?.termination_date || coverage?.expiration_date}
                    </TableCell>
                    <TableCell>
                      {coverage?.employer_sponsored && "True"}{" "}
                      {!coverage?.employer_sponsored && "False"}
                    </TableCell>
                    <TableCell>{coverage?.premium_cost}</TableCell>
                    <TableCell>{coverage?.user_cost}</TableCell>
                    <TableCell>
                      <Chip
                        label={coverage?.status}
                        color={
                          (coverage?.status === "active" && "secondary") ||
                          (coverage?.status === "expired" && "primary") ||
                          "warning"
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              navigate("/account/coverage");
            }}
          >
            Update Coverage
          </Button>
        </Box>
      </Card>
    );
  } else {
    return (
      <Card>
        <Alert severity="error">{error}</Alert>
      </Card>
    );
  }
}
