//External Imports
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";

//Internal Imports

const socialLinks = [
  {
    url: "https://github.com/tpafs",
    icon: <GitHubIcon />,
    ariaLabel: "Github acct",
  },
  {
    url: "https://twitter.com/PersiusOrg",
    icon: <TwitterIcon />,
    ariaLabel: "Twitter acct",
  },
  {
    url: "https://youtube.com/@Persius-org",
    icon: <YoutubeIcon />,
    ariaLabel: "Youtube acct",
  },
  {
    url: "https://instagram.com/persiusorg",
    icon: <InstagramIcon />,
    ariaLabel: "Instagram acct",
  },
];

export default function SocialLinks(props) {
  return (
    <Container align="center" maxWidth="sm">
      <Grid container justifyContent="center" direction="row" spacing={8}>
        {socialLinks.map((socialLink) => (
          <Grid item key={socialLink.url}>
            <Link href={socialLink.url} aria-label={socialLink.ariaLabel}>
              {socialLink.icon}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
