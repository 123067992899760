// External Imports
import React from "react";
import { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";

// Internal Imports
import { handle401 } from "../../utils/auth.ts";
import { editUser } from "../../api/accounts.ts";
import { getRegions } from "../../api/resources.ts";

export const ProfileDetails = (props) => {
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState({
    field: "",
    message: "",
  });
  const [editable, setEditable] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    age: "",
    region_name: "",
  });

  let user = props.user;
  let setUserCurrent = props.setUserCurrent;

  useEffect(() => {
    if (user.username !== undefined) {
      setValues((values) => ({ ...values, first_name: user.first_name }));
      setValues((values) => ({ ...values, last_name: user.last_name }));
      setValues((values) => ({ ...values, email: user.email }));
      setValues((values) => ({ ...values, age: user.age || "" }));
      setValues((values) => ({ ...values, phone: user.phone || "" }));
      setValues((values) => ({ ...values, city: user.city || "" }));
      setValues((values) => ({
        ...values,
        region_name: user.region?.name || "",
      }));
    }
  }, [user]);

  // TODO: Add some more legit validation
  function validProfile(values) {
    if (values.first_name.length === 0) {
      setValidationError({
        field: "first_name",
        message: "First name required.",
      });
    } else if (values.last_name.length === 0) {
      setValidationError({
        field: "last_name",
        message: "Last name required.",
      });
    } else if (values.age.length === 0) {
      setValidationError({ field: "age", message: "Age required." });
    } else if (!Number.isInteger(parseInt(values.age))) {
      // TODO: Filter decimals
      setValidationError({ field: "age", message: "Age must be an integer." });
    } else if (values.phone.length === 0) {
      setValidationError({ field: "phone", message: "Phone number required." });
    } else if (values.city.length === 0) {
      setValidationError({ field: "city", message: "City required." });
    } else if (values.region_name.length === 0) {
      setValidationError({ field: "region_name", message: "Region required." });
    } else {
      values.age = parseInt(values.age);
      return true;
    }
    return false;
  }

  useEffect(() => {
    setError(null);
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }, []);

  const handleChange = (event) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    let valid = validProfile(values);
    if (valid) {
      editUser(values);
      setEditable(false);
      setUserCurrent(false);
    }
  };

  const handleEdit = () => {
    setEditable(true);
  };

  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardHeader
          subheader="Tell us about yourself. Submitting this info is optional."
          title="Your Data"
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{
            align: "center",
          }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={validationError.field === "first_name"}
                helperText={
                  validationError.field === "first_name" &&
                  validationError.message
                }
                label="First name"
                name="first_name"
                onChange={handleChange}
                required
                InputProps={{
                  readOnly: !editable,
                }}
                value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={validationError.field === "last_name"}
                helperText={
                  validationError.field === "last_name" &&
                  validationError.message
                }
                label="Last name"
                InputProps={{
                  readOnly: !editable,
                }}
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: !editable,
                }}
                error={validationError.field === "age"}
                helperText={
                  validationError.field === "age" && validationError.message
                }
                label="Age"
                name="age"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                required
                value={values.age}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: !editable,
                }}
                error={validationError.field === "phone"}
                helperText={
                  validationError.field === "phone" && validationError.message
                }
                InputLabelProps={{ shrink: true }}
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: !editable,
                }}
                error={validationError.field === "city"}
                helperText={
                  validationError.field === "city" && validationError.message
                }
                InputLabelProps={{ shrink: true }}
                label="City"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
            {!error && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: !editable,
                  }}
                  label="State or Region"
                  name="region_name"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  required
                  select={editable}
                  SelectProps={{ native: true }}
                  error={validationError.field === "region_name"}
                  helperText={
                    validationError.field === "region_name" &&
                    validationError.message
                  }
                  value={values.region_name}
                  variant="outlined">
                  <option key="no_region" value="" disabled>
                    Select a Region
                  </option>
                  {regions.map((option) => (
                    <option key={option.uid} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            )}
            {error && (
              <Grid item md={6} xs={12}>
                <Alert severity="error">{error.message}</Alert>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}>
          {editable && (
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          )}
          {!editable && (
            <Button color="primary" variant="contained" onClick={handleEdit}>
              Update Info
            </Button>
          )}
        </Box>
      </Card>
    </form>
  );
};
